<template>
  <!-- 购买记录页面 -->
  <v-container fluid>
    <v-card align-center>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="2" >
            <v-btn color="primary" @click="createContract()" v-if="$root.authority=='ROLE_ADMIN' || $root.authority=='ROLE_USER_ADMIN'">{{$t('lang.contractWizard.newContract')}}</v-btn>
          </v-col>
          <v-col cols="12" sm="4" offset-sm="6">
            <v-text-field v-model="searchByPurchaseHistory" :label="$t('lang.station.searchLabel')" append-icon="mdi-magnify"></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-subtitle v-if="$root.authority=='ROLE_USER' && purchaseHistoryList.filter(item => item.payTime == null).length > 0">
        <div>
          <span class="orange--text mr-1">{{$t('lang.purchaseHistory.unpaidTip')}}</span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-bank-transfer</v-icon>
            </template>
            <div><span class="font-weight-bold">{{$t('lang.quickBuy.transfer.bank')}}: </span><span>{{$t('lang.quickBuy.transfer.bankName')}}</span></div>
            <div><span class="font-weight-bold">{{$t('lang.quickBuy.transfer.account')}}: </span><span>6214****3420</span></div>
            <div><span class="font-weight-bold">{{$t('lang.quickBuy.transfer.companyName')}}: </span><span>{{$t('lang.quickBuy.transfer.wuzhou')}}</span></div>
            <div><span class="font-weight-bold">{{$t('lang.quickBuy.transfer.phone')}}: </span><span>0631-3683331</span></div>
          </v-tooltip>
        </div>
      </v-card-subtitle>
      <v-card-text>
        <v-data-table :search="searchByPurchaseHistory" :headers="purchaseHistoryHeaders" :items="purchaseHistoryList">
          <template v-slot:item.actions="{item}">
            <!-- <v-btn small color="primary lighten-1" class="mr-2" @click="pay(item)">{{$t('lang.purchaseHistory.payBtn')}}</v-btn> -->            
            <!-- 续费 -->
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" small color="primary lighten-1" class="mr-2" @click="renewal(item)">
                  <v-icon small>mdi-cash</v-icon>
                </v-btn>
              </template>
              <span>{{$t('lang.purchaseHistory.renewalBtn')}}</span>
            </v-tooltip> -->
            
            <!-- 付款 -->
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" small color="primary lighten-1" class="mr-2" @click="pay(item)" v-if="!item.payTime && item.userId == $root.userId">
                  <v-icon small>mdi-cash</v-icon>
                </v-btn>
              </template>
              <span>{{$t('lang.purchaseHistory.payBtn')}}</span>
            </v-tooltip> -->

            <!-- 已付 -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" small color="primary lighten-1" class="mr-2" @click="showPaidDialog=true;paidOrderId=item.id" v-if="!item.payTime && $root.authority != 'ROLE_USER'">
                  <v-icon small>mdi-cash</v-icon>
                </v-btn>
              </template>
              <span>{{$t('lang.purchaseHistory.paidBtn')}}</span>
            </v-tooltip>

            <!-- 合同描述信息 -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" small color="grey lighten-1" class="mr-2" v-if="item.contract && item.contract.hasOwnProperty('description')">
                  <v-icon small>mdi-information-outline</v-icon>
                </v-btn>
              </template>
              <span>{{item.contract ? item.contract.description : ''}}</span>
            </v-tooltip>

            <!-- 删除 -->
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" small color="error lighten-1" class="">
                  <v-icon small >mdi-trash-can</v-icon>
                </v-btn>
              </template>
              <span>{{$t('lang.station.deleteBtn')}}</span>
            </v-tooltip> -->
          </template>
           <!-- <template v-slot:item.contract.name="{item}">
            <span v-if="item.contract.name == 'basic'">{{$t('lang.package.basicName')}}</span>
            <span v-else>{{$t('lang.package.educationName')}}</span>
          </template>
          <template v-slot:item.contract.type="{item}">
            <span v-if="item.contract.type == 'free'">{{$t('lang.package.freeTrial')}}</span>
            <span v-else>{{$t('lang.package.billedMonthly')}}</span>
          </template> -->
          <template v-slot:item.payAmount="{item}">
             <span>{{item.payAmount / 100}}</span>
          </template>
          <template v-slot:item.createdAt="{item}">
             <span>{{dateToString(item.createdAt)}}</span>
          </template>
          <template v-slot:item.payTime="{item}">
             <span>{{item.payTime ? dateToString(item.payTime) : ''}}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <!-- <v-dialog v-model="showStepperDialog" max-width="600">
      <payment-stepper :packageDetail="packageDetail" v-on:closeStepperDialog="closeStepperDialog"/>
    </v-dialog> -->
    <!-- 合同内容对话框 -->
    <v-dialog v-model="showContractDialog" max-width="500">
      <contract-wizard :contractContent.sync="contractContent" v-on:getContractRefForm="getContractRefForm" v-on:closeContractDialog="closeContractDialog" :getPurchaseList="getPurchaseList"/>
    </v-dialog>
    <!-- 已付对话框 可以设置已付款的时间 -->
    <v-dialog v-model="showPaidDialog" max-width="500">
      <v-card>
        <v-card-title>{{$t('lang.purchaseHistory.paidDialogTitle')}}</v-card-title>
        <v-card-text>
          <v-datetime-picker :label="$t('lang.purchaseHistory.selectPaidTime')" v-model="paidTime" time-format="HH:mm:ss" :time-picker-props="timeProps"> 
            <template v-slot:dateIcon>
              <v-icon>mdi-calendar-outline</v-icon>
            </template>
            <template v-slot:timeIcon>
              <v-icon>mdi-clock-time-five-outline</v-icon>
            </template>
          </v-datetime-picker>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="" @click="showPaidDialog = false">{{$t('lang.station.cancelBtn')}}</v-btn>
          <v-btn class="primary" @click="paid()">{{$t('lang.station.saveBtn')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar :timeout="tips.timeout" v-model="tips.showSnackbar" :color="tips.snackbarColor" :top="true">{{ tips.snackbarText }}</v-snackbar>
  </v-container>
</template>
<script>
import axios from 'axios';
import qs from 'qs'
import commonCfg from "../../config/common";
let apiUrl = commonCfg.commonUrl;
//import paymentStepper from "../../components/paymentStepper.vue";
import contractWizard from "../../components/contractWizard.vue";
export default {
  components: { contractWizard },
  data () {
    return {
      purchaseHistoryHeaders: [
        {text: this.$t('lang.purchaseHistory.historyId'), value: 'id'},
        {text: this.$t('lang.register.usernameLabel'),value: 'userId'},
        // {text: this.$t('lang.paymentStepper.package.name'),value: 'contract.name'},
        // {text: this.$t('lang.paymentStepper.package.type'),value: 'contract.type'},
        {text: this.$t('lang.purchaseHistory.stationCounts'), value: 'stationNum'},
        {text: `${this.$t('lang.purchaseHistory.serviceTime')}/${this.$t('lang.package.month')}`, value: 'serviceMonths'},
        {text: `${this.$t('lang.paymentStepper.contract.dataStorageTime')}/${this.$t('lang.purchaseHistory.day')}`, value: 'dataHoldDays'},
        {text: this.$t('lang.purchaseHistory.dataFormat'), value: 'dataFormat'},
        {text: this.$t('lang.purchaseHistory.purchaseTime'), value: 'createdAt'},
        {text: `${this.$t('lang.paymentStepper.package.totalAmount')}/￥`, value: 'payAmount'},     
        //{text: this.$t('lang.purchaseHistory.paymentMethod'), value: 'payMethod'},
        //{text: this.$t('lang.purchaseHistory.paymentTime'), value: 'payTime'},
        // {text: `${this.$t('lang.paymentStepper.package.unitPrice')}(￥)`,value: 'unitPrice'},
        // {text: this.$t('lang.contractWizard.remainingTime'),value: 'remainingDuration'},
        // {text: this.$t('lang.paymentStepper.contract.totalServices'),value: 'totalTimes'},
        // {text: this.$t('lang.contractWizard.remainingTimes'), value: 'remainingTimes'},
        {text: this.$t('lang.purchaseHistory.payTime'), value: 'payTime'},
        { value: "actions", text: this.$t('lang.station.operation'), sortable: false },

      ],
      purchaseHistoryList: [
         
      ],
      searchByPurchaseHistory: '',
      // packageDetail: {
      //   packageName: "",
      //   unitPrice: "",
      //   type: "",
      //   id: '',
      // },
      // showStepperDialog: false,
      contractContent: {
        flag: "",
        user: "",
        totalAmount: "",
        stationCounts: '',
        serviceTime: '',
        storageTime: "",
        dataFormat: "",
        description: "",
      },
      showContractDialog: false,
      contractRefForm: '',
      showPaidDialog: false,
      paidOrderId: '',
      paidTime: '',
      timeProps: {
        useSeconds: true,
        ampmInTitle: true
      },
      tips: {
        showSnackbar: false,
        snackbarColor: "error",
        snackbarText: "",
        timeout: -1
      }
    }
  },
  created() {
    this.getPurchaseList()
  },
  methods: {
    // renewal(item) {
    //   this.packageDetail = {
    //     packageName: item.packageName,
    //     unitPrice: item.unitPrice,
    //     type: item.packageType,
    //     id: item.packageId,
    //   },
    //   this.showStepperDialog = true
    // },
    // closeStepperDialog(status) {
    //   //status就是从子组件传来的状态值
    //   this.showStepperDialog = status 
    // },
    getPurchaseList() {
      axios.get(`${apiUrl}order/list`).then(res => {
        console.log(res)
        if(res.status == 200) {
          this.purchaseHistoryList = res.data
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // pay(item) {
    //   //id unitprice numberOfStations dataFormat
    //   console.log(item)
    //   this.packageDetail = {
    //     showPayStep: true,
    //     tradeNo: item.tradeNo
    //   }
    //    this.showStepperDialog = true
    // },
    getContractRefForm(form){
      //form是从子组件传递的合同表单
      this.contractRefForm = form
    },
    createContract() {
      this.contractContent = {
        flag: "new",
        user: "",
        totalAmount: "",
        stationCounts: '',
        serviceTime: '',
        storageTime: "",
        dataFormat: "",
        description: "",
      };
      this.showContractDialog = true;
      if(this.contractRefForm) this.contractRefForm.resetValidation()
    },
    closeContractDialog(status) {
      //status就是从子组件传来的状态值
      this.showContractDialog = status
    },
    //已付
    paid() {
      let param = {
        orderId: this.paidOrderId,
        payMethod: 'transfer',
        payTime: Math.round(Date.parse(this.paidTime)/1000),
      }
      console.log(param,qs)
      this.showPaidDialog = false
      axios.post(`${apiUrl}order/update/paid`, qs.stringify(param)).then(res => {
        console.log(res)
        if(res.status == 200) {
          this.showTips(this.$t("lang.tips.operationSuccess"), "success");
          this.getPurchaseList()
          return
        }
        this.showTips(this.$t("lang.tips.operationFailed"), "error");
      }).catch(error => {
        console.log(error)
        this.showTips(this.$t("lang.tips.operationFailed"), "error");
      })
    },
    //通知提示工具
    showTips (text, color, timeout) {
      this.tips.showSnackbar = true;
      this.tips.snackbarText = text;
      this.tips.snackbarColor = color;
      if(timeout) {
        this.tips.timeout = timeout
      }else{
        this.tips.timeout = 3000
      }
    },
    dateToString(timestamp) {
      var date = new Date(timestamp);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
  },
  
}
</script>