<template>
  <!-- 合同表单 -->
  <v-card>
    <v-card-title>{{ contractContent.flag == 'new' ? $t('lang.contractWizard.newContract') : $t('lang.contractWizard.contractEditor') }}</v-card-title>
    <v-card-text>
      <v-form ref="contractContent">
      <v-row dense>
        <v-col cols="12" class="mt-2">
          <v-autocomplete :label="$t('lang.paymentStepper.contract.partyA')" dense v-model="contractContent.user" :readonly="contractContent.readOnlyFlag" :rules="partyARules" :items="generalUserList" item-text="username" item-value="id" return-object></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <v-text-field :label="`${$t('lang.paymentStepper.contract.totalAmount')}/￥`" dense v-model="contractContent.totalAmount" :readonly="contractContent.readOnlyFlag" type="number" :rules="amountRules"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field :label="$t('lang.purchaseHistory.stationCounts')" dense v-model="contractContent.stationCounts" :readonly="contractContent.readOnlyFlag" type="number" :rules="stationCountsRules"></v-text-field>
        </v-col>
        <!-- <v-col cols="12">
          <v-text-field :label="$t('lang.paymentStepper.contract.totalServices')" dense v-model="contractContent.totalTimes" :readonly="contractContent.readOnlyFlag" type="number" :rules="serviceTimesRules"></v-text-field>
        </v-col> -->
        <v-col cols="12">
          <v-select :label="$t('lang.purchaseHistory.serviceTime')" dense :items="timeOptions" return-object item-text="text" item-value="value" v-model="contractContent.serviceTime" :readonly="contractContent.readOnlyFlag" :rules="serviceTimeRules"></v-select>
        </v-col>
        <v-col cols="12">
          <v-select :label="$t('lang.paymentStepper.contract.dataStorageTime')" dense :items="timeOptions" return-object item-text="text" item-value="value" v-model="contractContent.storageTime" :readonly="contractContent.readOnlyFlag" :rules="storageTimeRules"></v-select>
        </v-col>
        <v-col cols="12">
          <v-select :label="$t('lang.paymentStepper.contract.dataReturnFormat')" dense :items="dataFormat" v-model="contractContent.dataFormat" :readonly="contractContent.readOnlyFlag" :rules="dataFormatRules"></v-select>
        </v-col>
        <v-col cols="12">
          <v-textarea
            :label="$t('lang.paymentStepper.contract.contractDescription')"
            dense
            v-model="contractContent.description"
            :readonly="contractContent.readOnlyFlag"
            :rules="descriptionRules"
            :counter="300"
          ></v-textarea>
        </v-col>
      </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="grey" @click="showDialog = false;cancel()">{{$t('lang.station.cancelBtn')}}</v-btn>
      <v-btn color="primary" @click="save()">{{$t('lang.station.saveBtn')}}</v-btn>
    </v-card-actions>
    <v-snackbar
      :timeout="tips.timeout"
      v-model="tips.showSnackbar"
      :color="tips.snackbarColor"
      :top="true"
    >{{ tips.snackbarText }}</v-snackbar>
  </v-card>
</template>
<script>
import axios from "axios"
import commonCfg from "../config/common";
let apiUrl = commonCfg.commonUrl;
export default {
  data() {
    return {
      dataFormat: ["JSON"],
      partyARules: [
       v => !!v || this.$t('lang.register.usernameRules.required')
      ],
      amountRules: [
        v => !!v || this.$t('lang.package.priceRules.required'),
        v => /^[.0-9]+$/.test(v) || this.$t('lang.package.priceRules.specialCharacterRestriction'),
      ],
      stationCountsRules: [
        v => !!v || this.$t('lang.contractWizard.stationCountsRules.required'),
        v => /^[0-9]+$/.test(v) || this.$t('lang.contractWizard.stationCountsRules.specialCharacterRestriction'),
      ],
      // serviceTimesRules: [
      //   v => !!v || this.$t('lang.contractWizard.serviceTimesRules.required'),
      //   v => /^[0-9]+$/.test(v) || this.$t('lang.contractWizard.serviceTimesRules.specialCharacterRestriction'),
      // ],
      serviceTimeRules: [
        v => !!v || this.$t('lang.contractWizard.serviceTimeRules.required'),
      ],
      storageTimeRules: [
        v => !!v || this.$t('lang.contractWizard.storageTimeRules.required'),
      ],
      dataFormatRules: [
        v => !!v || this.$t('lang.contractWizard.dataFormatRules.required'),
      ],
      descriptionRules: [
        v => !!v || this.$t('lang.contractWizard.descriptionRules.required'),
        v => /^[\u4e00-\u9fa5_$，。；：？“”,.;:?"" a-zA-Z0-9]+$/.test(v) || this.$t('lang.contractWizard.descriptionRules.specialCharacterRestriction'),
        v => v.length <= 300 || this.$t('lang.contractWizard.descriptionRules.maximumCharacterLimit'),
      ],
      showDialog: true,
      generalUserList: [],
      timeOptions: [
        // { text: this.$t('lang.quickBuy.fifteenDays'), dayValue: 15, monthValue: 0.5 },
        { text: this.$t('lang.quickBuy.oneMonth'), dayValue: 30, monthValue: 1 },
        { text: this.$t('lang.quickBuy.twoMonths'), dayValue: 60, monthValue: 2 },
        { text: this.$t('lang.quickBuy.threeMonths'), dayValue: 90, monthValue: 3 },
        { text: this.$t('lang.quickBuy.halfYear'), dayValue: 180, monthValue: 6 },
        { text: this.$t('lang.quickBuy.oneYear'), dayValue: 365, monthValue: 12 },
        { text: this.$t('lang.quickBuy.twoYears'), dayValue: 730, monthValue: 24 },
        { text: this.$t('lang.quickBuy.threeYears'), dayValue: 1095, monthValue: 36 },
        { text: this.$t('lang.quickBuy.fourYears'), dayValue: 1460, monthValue: 48 },
        { text: this.$t('lang.quickBuy.fiveYears'), dayValue: 1825, monthValue: 60 },
      ],
      tips: {
        showSnackbar: false,
        snackbarColor: "error",
        snackbarText: "",
        timeout: -1,
      },
    };
  },
  props: ["contractContent", "getPurchaseList"],//父组件传来的参数
  created() {
    console.log(this.contractContent);
    if(this.$root.authority == 'ROLE_ADMIN' || this.$root.authority == 'ROLE_USER_ADMIN')this.getUserList()
  },
  mounted() {
    //传递form给父组件
    this.$emit('getContractRefForm', this.$refs.contractContent)
  },
  methods: {
    //保存
    save() {
      if(!this.$refs.contractContent.validate()) return
      let param = {
        userId: this.contractContent.user.id,
        contract: {
          description: this.contractContent.description
        },
        payAmount: this.contractContent.totalAmount,
        stationNum: this.contractContent.stationCounts,
        dataHoldDays: this.contractContent.storageTime.dayValue,
        serviceMonths: this.contractContent.serviceTime.monthValue,
        dataFormat: this.contractContent.dataFormat
      }
      this.createOrder(param)
    },
    cancel() {
      this.closeContractDialog()
    },
    closeContractDialog() {
      //子组件传递 关闭合同模板对话框的状态值 给父组件
      this.$emit('closeContractDialog', this.showDialog)
    },
    //获得用户列表
    getUserList() {
      axios
        .get(`${apiUrl}admin/user/list`)
        .then((res) => {
          console.log(res.data);
          if (res.status == 200) {
            this.generalUserList = res.data.filter(
              (data) => data.authority == "ROLE_USER"
            );
          }
        })
        .catch((error) => {
          console.log(error);
          this.generalUserList = [];
        });
    },
    //创建订单请求
    createOrder(param) {
      console.log(param)
      axios.post(`${apiUrl}admin/order/create`, param).then(res => {
        console.log(res)
        if(res.status == 200) {
          this.showDialog = false
          this.closeContractDialog()
          this.showTips(this.$t("lang.tips.operationSuccess"), "success");
          this.getPurchaseList()
          return
        }
        this.showTips(this.$t("lang.tips.operationFailed"), "error");
      }).catch(error => {
        console.log(error)
        this.showTips(this.$t("lang.tips.operationFailed"), "error");
      })
    },
    //通知提示工具
    showTips(text, color, timeout) {
      this.tips.showSnackbar = true;
      this.tips.snackbarText = text;
      this.tips.snackbarColor = color;
      if (timeout) {
        this.tips.timeout = timeout;
      } else {
        this.tips.timeout = 3000;
      }
    },
  }
};
</script>